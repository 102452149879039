import React, { RefObject } from "react"
import { device, size } from "../../styles/media"

import { TeamMember } from "./TeamMember"
import { TeamMember as TeamMemberType } from "../../types/TeamMember"
import { render } from "react-dom"
import styled from "styled-components"

function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  )
}

const FlexContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 80px;
  @media ${device.tablet} {
    padding: 0 20px 110px;
  }
`

const DescriptionContainer = styled.div`
  flex: 1 0 100%;
  font-size: 18px;
  line-height: 32px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  margin-top: 46px;
  padding: 0 calc(16% - 238px / 2);
  .text {
    margin-bottom: 24px;
  }
`
const PhoneNumber = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`

const Title = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
`

// const teamMembers = [
//   {
//     name: "Name Surname",
//     experience: "10 years of experience",
//     fields: ["Lorem", "Lorem", "Lorem"],
//     description: "lorem 1",
//     phoneNumber: "+380 98 74 82 111",
//   },
//   {
//     name: "Name Surname",
//     experience: "10 years of experience",
//     fields: ["Lorem", "Lorem", "Lorem"],
//     description: "lorem 2",
//     phoneNumber: "+380 98 74 82 111",
//   },
//   {
//     name: "Name Surname",
//     experience: "10 years of experience",
//     fields: ["Lorem", "Lorem", "Lorem"],
//     description: "lorem 3",
//     phoneNumber: "+380 98 74 82 111",
//   },
//   {
//     name: "Name Surname",
//     experience: "10 years of experience",
//     fields: ["Lorem", "Lorem", "Lorem"],
//     description: "lorem 4",
//     phoneNumber: "+380 98 74 82 111",
//   },
// ]

export class TeamMemberContainer extends React.Component<
  {
    teamMembers: TeamMemberType[]
    phoneNumberText: string
    areasTitle: string
    learnMoreText: string
  },
  { itemsCount: number }
> {
  container = null
  index = null

  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.index = null
    this.state = {
      itemsCount: 3,
    }
  }

  componentDidMount() {
    this.updateCount()
  }

  updateCount = () => {
    if (canUseDOM()) {
      if (window.innerWidth > size.tablet) {
        console.log("here 3")
        this.setState({
          itemsCount: 3,
        })
      } else if (
        window.innerWidth <= size.tablet &&
        window.innerWidth > size.mobileL
      ) {
        console.log("here two")
        this.setState({
          itemsCount: 2,
        })
      } else {
        console.log("here one")
        this.setState({
          itemsCount: 1,
        })
      }

      window.addEventListener("resize", () => {
        if (window.outerWidth > size.tablet) {
          this.setState({
            itemsCount: 3,
          })
        } else if (
          window.outerWidth <= size.tablet &&
          window.outerWidth > size.mobileL
        ) {
          this.setState({
            itemsCount: 2,
          })
        } else {
          this.setState({
            itemsCount: 1,
          })
        }
      })
    }
  }

  checkRow = index => {
    console.log(index)
    const parent = this.container.current
    const oldDescription = parent.querySelector(".description")
    const children = parent.querySelectorAll(
      `div[class^='TeamMember__TeamMemberBlock']`
    )
    const element = document.createElement("div")
    element.classList.add("description-container")
    render(
      <Description
        description={this.props.teamMembers[index].description}
        phoneNumber={this.props.teamMembers[index].phoneNumber}
        phoneNumberText={this.props.phoneNumberText}
      />,
      element
    )
    element.classList.add("description")
    if (index !== this.index) {
      this.insertAfter(children[this.checkLastInRow(index)], element)
      this.index = index
    } else {
      this.index = null
    }
    if (oldDescription) {
      parent.removeChild(oldDescription)
    }
  }

  insertAfter = (referenceNode, newNode) => {
    referenceNode?.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }

  checkLastInRow = index => {
    let itemCountInRow = this.state.itemsCount

    const lastItemIndexInRow =
      Math.ceil((index + 1) / itemCountInRow) * itemCountInRow

    return lastItemIndexInRow > this.props.teamMembers.length - 1
      ? this.props.teamMembers.length - 1
      : lastItemIndexInRow - 1
  }

  render() {
    return (
      <>
        <Title>Наша команда</Title>
        <FlexContainer ref={this.container}>
          {this.props.teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              photo={member.photo}
              experience={member.experience}
              fields={member.fields}
              className="team-member"
              areasTitle={this.props.areasTitle}
              moreButtonClick={() => this.checkRow(index)}
              learnMoreText={this.props.learnMoreText}
            />
          ))}
        </FlexContainer>
      </>
    )
  }
}

class Description extends React.Component<{
  description: string
  phoneNumber: string
  phoneNumberText: string
}> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <DescriptionContainer>
        <p className="text">{this.props.description}</p>
        <PhoneNumber>
          {this.props.phoneNumberText}: {this.props.phoneNumber}
        </PhoneNumber>
      </DescriptionContainer>
    )
  }
}
