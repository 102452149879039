import React, { FC } from "react"
import styled from "styled-components"
import { device } from "../../styles/media"

const CountersContainer = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 80px 50px 220px;
  @media ${device.laptop} {
    padding: 0 20px 50px;
  }
  @media ${device.mobileL} {
    padding: 0;
    flex-direction: column-reverse;
  }
`

const CounterBlock = styled.div`
  border-left: 2px solid #fff;
  color: #fff;
  display: flex;
  align-items: flex-end;
  padding-left: 8px;
  :first-child {
    margin-left: 0;
  }
  @media ${device.mobileL} {
    align-items: center;
    border-left: none;
    padding-left: 45px;
    height: 88px;
  }
`

const CounterNumber = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 36px;
  @media ${device.mobileL} {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    margin-right: 20px;
  }
`

const CounterTitle = styled.p`
  font-size: 24px;
  line-height: 24px;
  font-weight: 200;
  margin-left: 8px;
  width: 100%;
  max-width: 158px;
  @media ${device.mobileL} {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    max-width: none;
  }
`

export const TopBlockCounters: FC<{
  counters: { number: number; title: string }[]
}> = ({ counters }) => {
  return (
    <CountersContainer>
      {counters.map((counter, index) => (
        <CounterBlock key={index}>
          <CounterNumber>{counter.number}</CounterNumber>
          <CounterTitle>{counter.title}</CounterTitle>
        </CounterBlock>
      ))}
    </CountersContainer>
  )
}
