import React from "react"
import styled from "styled-components"
import { device } from "../../styles/media"

export const BlockTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  @media ${device.mobileL} {
    margin-top: 30px;
  }
`
