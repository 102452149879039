import React from "react"
import styled from "styled-components"

import topBlockBg from "../../images/top-block-main.png"
import { device } from "../../styles/media"

export const TopBlock = styled.div`
  width: 100%;
  height: 552px;
  background-image: url(${topBlockBg});
  background-position: center;
  position: relative;
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`
