import React from "react"
import styled from "styled-components"
import { device } from "../../styles/media"

export const Container = styled.div<{ padding?: string; background?: string }>`
  width: 100%;
  max-width: 1440px;
  background: ${props => props.background};
  margin: auto;
`

export const ContainerWithPadding = styled.div<{
  padding?: string
  background?: string
}>`
  width: 100%;
  max-width: 1440px;
  padding: 32px 32px 0;
  background: ${props => props.background};
  margin: auto;
  @media ${device.tablet} {
    padding: 0;
  }
`
