import React, { useEffect, useRef, FC } from "react"

import styled from "styled-components"
import teamMember from "../../images/team-member.png"
import { device } from "../../styles/media"

const TeamMemberBlock = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`

const TeamMememberContentContainer = styled.div`
  width: 100%;
  max-width: 251px;
  margin-top: 90px;
`

const TeamMemberPhoto = styled.img`
  width: 248px;
  height: 306px;
  object-fit: cover;
  margin-bottom: 22px;
`

const TeamMemberName = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

const TeamMemberAdditionalFields = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
`

const TeamMemberAreasTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

const MoreInfoButton = styled.button`
  height: 41px;
  background: #6cbcde;
  border: 1px solid #89d0d7;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  color: #fff;
  &:hover {
    background: #000000;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`

export const TeamMember: FC<{
  photo?: string
  name?: string
  fields?: string[]
  experience?: string
  text?: string
  parentOffset?: number
  index?: number
  moreButtonClick: (index: number) => void
  className: string
  areasTitle: string
  learnMoreText: string
}> = ({
  photo,
  name,
  fields,
  experience,
  text,
  parentOffset,
  index,
  moreButtonClick,
  className,
  areasTitle,
  learnMoreText,
}) => {
  const memberRef = useRef(null)

  return (
    <>
      <TeamMemberBlock ref={memberRef}>
        <TeamMememberContentContainer>
          <TeamMemberPhoto src={photo} />
          <TeamMemberName>{name}</TeamMemberName>
          <TeamMemberAdditionalFields>
            <TeamMemberAreasTitle>{areasTitle}:</TeamMemberAreasTitle>{" "}
            {fields.join(", ")}
          </TeamMemberAdditionalFields>
          <TeamMemberAdditionalFields>{experience}</TeamMemberAdditionalFields>
          <MoreInfoButton onClick={() => moreButtonClick(index)}>
            {learnMoreText}
          </MoreInfoButton>
        </TeamMememberContentContainer>
      </TeamMemberBlock>
    </>
  )
}
